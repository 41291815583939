import type { FormKitNode } from '@formkit/core'
import { z } from 'zod'
import { addressSchema, emailSchema, mobilePhoneSchema } from '~/server/schemas'

export const email = (node: FormKitNode) => {
  if (!node.value) {
    return false
  }
  const vildatedEmail = emailSchema.safeParse(node.value)
  if (vildatedEmail.success) {
    return true
  }
  return false
}

export const mobilePhone = (node: FormKitNode) => {
  if (!node.value) {
    return false
  }
  const validatedMobilePhone = mobilePhoneSchema.safeParse(node.value)
  if (validatedMobilePhone.success) {
    return true
  }
  return false
}

export const dateOnOrAfter = (node: FormKitNode, date: string | number | Date) => {
  if (!node.value || !date) {
    return false
  }

  const parsedTargetDate = z.coerce.date().parse(date)
  const parsedNodeValue = z.coerce.date().parse(node.value)

  return parsedNodeValue >= parsedTargetDate
}

export const address = (node: FormKitNode) => {
  return addressSchema.safeParse(node.value).success
}
