import revive_payload_client_vEhqIeiCGr from "/app/node_modules/.pnpm/nuxt@3.12.3_@azure+identity@4.4.0_@parcel+watcher@2.4.1_@types+node@20.11.30_eslint@8.57.0_io_72liswitvc5j3cmjkorex4v3xm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_sogW4kGLKh from "/app/node_modules/.pnpm/nuxt@3.12.3_@azure+identity@4.4.0_@parcel+watcher@2.4.1_@types+node@20.11.30_eslint@8.57.0_io_72liswitvc5j3cmjkorex4v3xm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_x17WD0NjRf from "/app/node_modules/.pnpm/nuxt@3.12.3_@azure+identity@4.4.0_@parcel+watcher@2.4.1_@types+node@20.11.30_eslint@8.57.0_io_72liswitvc5j3cmjkorex4v3xm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Ap493J3odG from "/app/node_modules/.pnpm/nuxt@3.12.3_@azure+identity@4.4.0_@parcel+watcher@2.4.1_@types+node@20.11.30_eslint@8.57.0_io_72liswitvc5j3cmjkorex4v3xm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_U2WwsiH2ID from "/app/node_modules/.pnpm/nuxt@3.12.3_@azure+identity@4.4.0_@parcel+watcher@2.4.1_@types+node@20.11.30_eslint@8.57.0_io_72liswitvc5j3cmjkorex4v3xm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_jSg7RrIzvv from "/app/node_modules/.pnpm/nuxt@3.12.3_@azure+identity@4.4.0_@parcel+watcher@2.4.1_@types+node@20.11.30_eslint@8.57.0_io_72liswitvc5j3cmjkorex4v3xm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_XkRTxO7pIb from "/app/node_modules/.pnpm/nuxt@3.12.3_@azure+identity@4.4.0_@parcel+watcher@2.4.1_@types+node@20.11.30_eslint@8.57.0_io_72liswitvc5j3cmjkorex4v3xm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_7Y0hfku3cY from "/app/node_modules/.pnpm/nuxt@3.12.3_@azure+identity@4.4.0_@parcel+watcher@2.4.1_@types+node@20.11.30_eslint@8.57.0_io_72liswitvc5j3cmjkorex4v3xm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_XGRo4YWS5L from "/app/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.3.2_magicast@0.3.4_rollup@4.18.0_webpack@5.91.0/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.mjs";
import i18n_9y4w0go6QZ from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.2.0_magicast@0.3.4_rollup@4.18.0_vue@3.4.31_typescript@5.5.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import formkitPlugin_pZqjah0RUG from "/app/.nuxt/formkitPlugin.mjs";
import colorMode_gPvyEo5Kn9 from "/app/node_modules/.pnpm/@bg-dev+nuxt-naiveui@1.11.0_magicast@0.3.4_rollup@4.18.0_vue@3.4.31_typescript@5.5.3_/node_modules/@bg-dev/nuxt-naiveui/dist/runtime/plugins/colorMode.mjs";
import plugin_2YoRWrUvxr from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.8.0_@azure+identity@4.4.0_magicast@0.3.4_next-auth@4.21.1_next@13.5.6_@_pks4lbifuwljc4m4tm3d4r5wx4/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import trpcClient_M8UiGTyzsx from "/app/plugins/trpcClient.ts";
import vue_query_wrmMkNpEpe from "/app/plugins/vue-query.ts";
export default [
  revive_payload_client_vEhqIeiCGr,
  unhead_sogW4kGLKh,
  router_x17WD0NjRf,
  payload_client_Ap493J3odG,
  navigation_repaint_client_U2WwsiH2ID,
  check_outdated_build_client_jSg7RrIzvv,
  chunk_reload_client_XkRTxO7pIb,
  components_plugin_KR1HBZs4kY,
  prefetch_client_7Y0hfku3cY,
  plugin_XGRo4YWS5L,
  i18n_9y4w0go6QZ,
  formkitPlugin_pZqjah0RUG,
  colorMode_gPvyEo5Kn9,
  plugin_2YoRWrUvxr,
  trpcClient_M8UiGTyzsx,
  vue_query_wrmMkNpEpe
]