import { useQuery } from '@tanstack/vue-query'
import type { PrismaQueryParameters } from '~/utils/useQueryParams'
import type { PrismaWhere, RouterInput } from '~/types'

export default () => {
  const { $trpc } = useNuxtApp()

  return {
    accountQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'account'>>) => useQuery({
        queryFn: () => $trpc.accounts.findManyAccount.query(unref(query)),
        queryKey: ['accounts', 'getAll', query],
      }),
      findOne: (where?: MaybeRef<PrismaWhere<'account'>>) => useQuery({
        queryFn: () => $trpc.accounts.findOneAccount.query(unref(where)),
        queryKey: ['accounts', 'getById', where],
        enabled() {
          return !!unref(where)?.id
        },
      }),
      findStats: (accountId: MaybeRef<string>) => useQuery({
        queryFn: () => $trpc.accounts.getAccountStats.query(unref(accountId)),
        queryKey: ['accounts', 'getById', 'stats', accountId],
      }),
    },
    personQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'person'>>) => useQuery({
        queryFn: () => $trpc.persons.findManyPerson.query(unref(query)),
        queryKey: ['persons', 'getAll', query],
      }),
      findOne: (where?: MaybeRef<PrismaWhere<'person'>>) => useQuery({
        queryFn: async () => $trpc.persons.findOnePerson.query(unref(where)),
        queryKey: ['persons', 'getById', where],
        enabled() {
          return !!unref(where)?.id
        },
      }),
    },
    diseaseQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'disease'>>) => useQuery({
        queryFn: () => $trpc.diseases.findManyDisease.query(unref(query)),
        queryKey: ['diseases', 'getAll', query],
      }),
      findOne: (where?: MaybeRef<PrismaWhere<'disease'>>) => useQuery({
        queryFn: () => $trpc.diseases.findOneDisease.query(unref(where)),
        queryKey: ['diseases', 'getById', where],
        enabled() {
          return !!unref(where)?.id
        },
      }),
    },
    vaccineQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'vaccine'>>) => useQuery({
        queryFn: () => $trpc.vaccines.findManyVaccine.query(unref(query)),
        queryKey: ['vaccines', 'getAll', query],
      }),
      findOne: (where?: MaybeRef<PrismaWhere<'vaccine'>>) => useQuery({
        queryFn: () => $trpc.vaccines.findOneVaccine.query(unref(where)),
        queryKey: ['vaccines', 'getById', where],
        enabled() {
          return !!unref(where)?.id
        },
      }),
    },
    vaccinationQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'vaccination'>>) => useQuery({
        queryFn: () => $trpc.vaccinations.findManyVaccination.query(unref(query)),
        queryKey: ['vaccinations', 'getAll', query],
      }),
      findOne: (where?: MaybeRef<PrismaWhere<'vaccination'>>) => useQuery({
        queryFn: () => $trpc.vaccinations.findOneVaccination.query(unref(where)),
        queryKey: ['vaccinations', 'getById', where],
        enabled() {
          return !!unref(where)?.id
        },
      }),
    },
    predispositionQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'predisposition'>>) => useQuery({
        queryFn: () => $trpc.predispositions.findManyPredisposition.query(unref(query)),
        queryKey: ['predispositions', 'getAll', query],
      }),
      findOne: (where?: MaybeRef<PrismaWhere<'predisposition'>>) => useQuery({
        queryFn: () => $trpc.predispositions.findOnePredisposition.query(unref(where)),
        queryKey: ['predispositions', 'getById', where],
        enabled() {
          return !!unref(where)?.id
        },
      }),
    },
    symptomQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'symptom'>>) => useQuery({
        queryFn: () => $trpc.symptoms.findManySymptom.query(unref(query)),
        queryKey: ['symptoms', 'getAll', query],
      }),
      findOne: (where?: MaybeRef<PrismaWhere<'symptom'>>) => useQuery({
        queryFn: () => $trpc.symptoms.findOneSymptom.query(unref(where)),
        queryKey: ['symptoms', 'getById', where],
        enabled() {
          return !!unref(where)?.id
        },
      }),
    },
    symptomDiaryEntriesQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'symptomDiaryEntry'>>) => useQuery({
        queryFn: () => $trpc.symptomDiaryEntries.findManySymptomDiaryEntry.query(unref(query)),
        queryKey: ['symptomDiaryEntries', 'getAll', query],
      }),
      findOne: (where?: MaybeRef<PrismaWhere<'symptomDiaryEntry'>>) => useQuery({
        queryFn: () => $trpc.symptomDiaryEntries.findOneSymptomDiaryEntry.query(unref(where)),
        queryKey: ['symptomDiaryEntries', 'getById', where],
        enabled() {
          return !!unref(where)?.id
        },
      }),
    },
    teamQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'caseTeam'>>, enabled = true) => useQuery({
        queryFn: () => $trpc.teams.findManyTeam.query(unref(query)),
        queryKey: ['teams', 'getAll', query],
        enabled,
      }),
      findOne: (where?: MaybeRef<PrismaWhere<'caseTeam'>>) => useQuery({
        queryFn: () => $trpc.teams.findOneTeam.query(unref(where)),
        queryKey: ['teams', 'getById', where],
        enabled() {
          return !!unref(where)?.id
        },
      }),
    },
    caseQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'case'>>) => useQuery({
        queryFn: () => $trpc.cases.findManyCase.query(unref(query)),
        queryKey: ['cases', 'getAll', query],
      }),
      findOne: (where?: MaybeRef<PrismaWhere<'case'>>) => useQuery({
        queryFn: () => $trpc.cases.findOneCase.query(unref(where)),
        queryKey: ['cases', 'getById', where],
        enabled() {
          return !!unref(where)?.id
        },
      }),
    },
    caseEventQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'caseEvent'>>) => useQuery({
        queryFn: () => $trpc.caseEvents.findManyCaseEvent.query(unref(query)),
        queryKey: ['caseEvents', 'getAll', query],
      }),
      findOne: (where?: MaybeRef<PrismaWhere<'caseEvent'>>) => useQuery({
        queryFn: () => $trpc.caseEvents.findOneCaseEvent.query(unref(where)),
        queryKey: ['caseEvents', 'getById', where],
        enabled() {
          return !!unref(where)?.id
        },
      }),
    },
    documentQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'document'>>) => useQuery({
        queryFn: () => $trpc.documents.findManyDocument.query(unref(query)),
        queryKey: ['documents', 'getAll', query],
      }),
      findOne: (where?: MaybeRef<PrismaWhere<'document'>>) => useQuery({
        queryFn: () => $trpc.documents.findOneDocument.query(unref(where)),
        queryKey: ['documents', 'getById', where],
        enabled() {
          return !!unref(where)?.id
        },
      }),
    },
    messageTemplateQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'messageTemplate'>>) => useQuery({
        queryFn: () => $trpc.messageTemplates.findManyMessageTemplate.query(unref(query)),
        queryKey: ['messageTemplates', 'getAll', query],
      }),
      findOne: (where?: MaybeRef<PrismaWhere<'messageTemplate'>>) => useQuery({
        queryFn: () => $trpc.messageTemplates.findOneMessageTemplate.query(unref(where)),
        queryKey: ['messageTemplates', 'getById', where],
        enabled() {
          return !!unref(where)?.id
        },
      }),
    },
    documentTemplateQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'documentTemplate'>>, enabled = true) => useQuery({
        queryFn: () => $trpc.documentTemplates.findManyDocumentTemplates.query(unref(query)),
        queryKey: ['documentTemplates', 'getAll', query],
        enabled,
      }),
      findOne: (where?: MaybeRef<PrismaWhere<'documentTemplate'>>) => useQuery({
        queryFn: () => $trpc.documentTemplates.findOneDocumentTemplate.query(unref(where)),
        queryKey: ['documentTemplates', 'getById', where],
        enabled() {
          return !!unref(where)?.id
        },
      }),
    },
    institutionQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'institution'>>) => useQuery({
        queryFn: () => $trpc.institutions.findManyInstitution.query(unref(query)),
        queryKey: ['institutions', 'getAll', query],
      }),
      findOne: (where?: MaybeRef<PrismaWhere<'institution'>>) => useQuery({
        queryFn: () => $trpc.institutions.findOneInstitution.query(unref(where)),
        queryKey: ['institutions', 'getById', where],
        enabled() {
          return !!unref(where)?.id
        },
      }),
    },
    importLogQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'importLogEntry'>>) => useQuery({
        queryFn: () => $trpc.importLogs.findManyImportLogs.query(unref(query)),
        queryKey: ['importLogs', 'getAll', query],
      }),
      getStats: () => useQuery({
        queryFn: () => $trpc.importLogs.getImportLogsStats.query(),
        queryKey: ['importLogs', 'stats'],
      }),
    },
    bearerTokenQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'bearerToken'>>) => useQuery({
        queryFn: () => $trpc.bearerTokens.findManyBearerToken.query(unref(query)),
        queryKey: ['bearerTokens', 'getAll', query],
      }),
    },
    reportQuery: {
      getDiseasesStatistics: (query?: MaybeRef<RouterInput['reports']['getDiseasesStatistics']>) => useQuery({
        queryFn: () => $trpc.reports.getDiseasesStatistics.query(unref(query)),
        queryKey: ['reports', 'getDiseasesStatistics', query],
      }),
      getInstitutionsStatistics: (query?: MaybeRef<RouterInput['reports']['getInstitutionsStatistics']>) => useQuery({
        queryFn: () => $trpc.reports.getInstitutionsStatistics.query(unref(query)),
        queryKey: ['reports', 'getInstitutionsStatistics', query],
      }),
      getCaseMap: (query?: MaybeRef<PrismaQueryParameters<'case'>>) => useQuery({
        queryFn: () => $trpc.reports.getCaseMap.query(unref(query)),
        queryKey: ['reports', 'getCaseMap', query],
      }),
    },
    diseaseTestQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'diseaseTest'>>) => useQuery({
        queryFn: () => $trpc.diseaseTests.findManyDiseaseTest.query(unref(query)),
        queryKey: ['diseaseTests', 'getAll', query],
      }),
      findOne: (where?: MaybeRef<PrismaWhere<'diseaseTest'>>) => useQuery({
        queryFn: () => $trpc.diseaseTests.findOneDiseaseTest.query(unref(where)),
        queryKey: ['diseaseTests', 'getById', where],
        enabled() {
          return !!unref(where)?.id
        },
      }),
    },
    outbreakQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'outbreak'>>) => useQuery({
        queryFn: () => $trpc.outbreaks.findManyOutbreak.query(unref(query)),
        queryKey: ['outbreaks', 'getAll', query],
      }),
      findOne: (where?: MaybeRef<PrismaWhere<'outbreak'>>) => useQuery({
        queryFn: () => $trpc.outbreaks.findOneOutbreak.query(unref(where)),
        queryKey: ['outbreaks', 'getById', where],
        enabled() {
          return !!unref(where)?.id
        },
      }),
      getOutbreakCaseTimeframe: (query?: MaybeRef<RouterInput['outbreaks']['getOutbreakCaseTimeframe']>) => useQuery({
        queryFn: () => $trpc.outbreaks.getOutbreakCaseTimeframe.query(unref(query)),
        queryKey: ['outbreaks', 'getOutbreakCaseTimeframe', query],
      }),
    },
    addressQuery: {
      autoCompleteFromQuery: (query: MaybeRef<string>) => useQuery({
        queryFn: () => $trpc.addresses.findManyAddressResults.query({ query: unref(query) }),
        queryKey: ['address', 'autocomplete', query],
        enabled() {
          return unref(query).length > 2
        },
      }),
    },
    filterTemplateQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'filterTemplate'>>) => useQuery({
        queryFn: () => $trpc.filterTemplates.findManyFilterTemplate.query(unref(query)),
        queryKey: ['filterTemplates', 'getAll', query],
      }),
    },
    institutionTypeQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'institutionType'>>) => useQuery({
        queryFn: () => $trpc.institutionTypes.findManyInstitutionType.query(unref(query)),
        queryKey: ['institutionTypes', 'getAll', query],
      }),
      findOne: (where?: MaybeRef<PrismaWhere<'institutionType'>>) => useQuery({
        queryFn: () => $trpc.institutionTypes.findOneInstitutionType.query(unref(where)),
        queryKey: ['institutionTypes', 'getById', where],
        enabled() {
          return !!unref(where)?.id
        },
      }),
    },
    immunizationQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'immunization'>>) => useQuery({
        queryFn: () => $trpc.immunizations.findManyImmunization.query(unref(query)),
        queryKey: ['immunizations', 'getAll', query],
      }),
      findOne: (where?: MaybeRef<PrismaWhere<'immunization'>>) => useQuery({
        queryFn: () => $trpc.immunizations.findOneImmunization.query(unref(where)),
        queryKey: ['immunizations', 'getById', where],
        enabled() {
          return !!unref(where)?.id
        },
      }),
    },
    addressBookEntryQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'addressBookEntry'>>) => useQuery({
        queryFn: () => $trpc.addressBookEntries.findManyAddressBookEntry.query(unref(query)),
        queryKey: ['addressBookEntries', 'getAll', query],
      }),
    },
    diseaseVariantQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'diseaseVariant'>>, enabled = true) => useQuery({
        queryFn: () => $trpc.diseaseVariants.findManyDiseaseVariant.query(unref(query)),
        queryKey: ['diseaseVariants', 'getAll', query],
        enabled,
      }),
      findOne: (where?: MaybeRef<PrismaWhere<'diseaseVariant'>>) => useQuery({
        queryFn: () => $trpc.diseaseVariants.findOneDiseaseVariant.query(unref(where)),
        queryKey: ['diseaseVariants', 'getById', where],
        enabled() {
          return !!unref(where)?.id
        },
      }),
    },
    hospitalizationQuery: {
      findMany: (query?: MaybeRef<PrismaQueryParameters<'hospitalization'>>) => useQuery({
        queryFn: () => $trpc.hospitalizations.findManyHospitalization.query(unref(query)),
        queryKey: ['hospitalizations', 'getAll', query],
      }),
      findOne: (where?: MaybeRef<PrismaWhere<'hospitalization'>>) => useQuery({
        queryFn: () => $trpc.hospitalizations.findOneHospitalization.query(unref(where)),
        queryKey: ['hospitalizations', 'getById', where],
        enabled() {
          return !!unref(where)?.id
        },
      }),
    },
    notesQuery: {
      findMany: (input: MaybeRef<{ personId: string, caseId?: string | null } | undefined>) => useQuery({
        queryFn: () => $trpc.notes.findManyNotesByPerson.query(unref(input)),
        enabled() {
          return !!unref(input)?.personId
        },
        queryKey: ['notes', 'getAll', input],
      }),
      findOne: (input: MaybeRef<{ id: string } | undefined>) => useQuery({
        queryFn: () => {
          const unrefInput = unref(input)
          if (unrefInput) {
            return $trpc.notes.findOneNote.query(unrefInput)
          }
        },
        enabled() {
          return !!unref(input)?.id
        },
        queryKey: ['notes', 'getById', input],
      }),
    },
  }
}
