import { default as bearerTokensHRKCscAfDGMeta } from "/app/pages/admin/bearerTokens.vue?macro=true";
import { default as create5SLJzlhNVGMeta } from "/app/pages/admin/disease/create.vue?macro=true";
import { default as indexY6Hb5RP5XtMeta } from "/app/pages/admin/disease/index.vue?macro=true";
import { default as _91diseaseId_93b4ek4qG7aTMeta } from "/app/pages/admin/disease/update/[diseaseId].vue?macro=true";
import { default as import_45logsCZ4s5eE7T3Meta } from "/app/pages/admin/import-logs.vue?macro=true";
import { default as institution_45typesSzoy9dfc3yMeta } from "/app/pages/admin/institution-types.vue?macro=true";
import { default as predispositionuNC8NHjNYNMeta } from "/app/pages/admin/predisposition.vue?macro=true";
import { default as symptoms9ZfejHPVXZMeta } from "/app/pages/admin/symptoms.vue?macro=true";
import { default as teamssxc90mmZDiMeta } from "/app/pages/admin/teams.vue?macro=true";
import { default as indexv6c4fXxPEPMeta } from "/app/pages/admin/templates/documents/index.vue?macro=true";
import { default as indexyKqtiUOVtyMeta } from "/app/pages/admin/templates/messages/index.vue?macro=true";
import { default as _91templateId_93QVqzx5jYguMeta } from "/app/pages/admin/templates/messages/update/[templateId].vue?macro=true";
import { default as vaccine818DgLWFSDMeta } from "/app/pages/admin/vaccine.vue?macro=true";
import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as logoutCHyXH6UWwkMeta } from "/app/pages/auth/logout.vue?macro=true";
import { default as registerTCvAsPw3TEMeta } from "/app/pages/auth/register.vue?macro=true";
import { default as resetZNqLl9CTHjMeta } from "/app/pages/auth/reset.vue?macro=true";
import { default as createb9OO4vn8rjMeta } from "/app/pages/cases/create.vue?macro=true";
import { default as _91caseId_93ylBV5Aq8uOMeta } from "/app/pages/cases/details/[caseId].vue?macro=true";
import { default as highriskuJIWlCq4zgMeta } from "/app/pages/cases/filter/highrisk.vue?macro=true";
import { default as indexxQtODpsYXtMeta } from "/app/pages/cases/index.vue?macro=true";
import { default as planned_45notificationsUqWPSosUWcMeta } from "/app/pages/cases/planned-notifications.vue?macro=true";
import { default as _91caseId_93aEZENql29xMeta } from "/app/pages/cases/update/[caseId].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91institutionId_933PZ1u3b499Meta } from "/app/pages/institutions/details/[institutionId].vue?macro=true";
import { default as indexjs6pUYy8CaMeta } from "/app/pages/institutions/index.vue?macro=true";
import { default as _91outbreakId_93StDWiP8dF8Meta } from "/app/pages/outbreaks/details/[outbreakId].vue?macro=true";
import { default as indexrK1EAZnX55Meta } from "/app/pages/outbreaks/index.vue?macro=true";
import { default as casemap43g9KqAyuRMeta } from "/app/pages/reports/casemap.vue?macro=true";
import { default as chartsBfPOZGcBieMeta } from "/app/pages/reports/charts.vue?macro=true";
import { default as diseasesS9RjJTHFUmMeta } from "/app/pages/reports/diseases.vue?macro=true";
import { default as institutionsm1KsSP5irpMeta } from "/app/pages/reports/institutions.vue?macro=true";
import { default as _91caseId_93Ku7eTP5NpyMeta } from "/app/pages/self-service/cases/details/[caseId].vue?macro=true";
import { default as indexxFpzrRKMlwMeta } from "/app/pages/self-service/cases/index.vue?macro=true";
import { default as _91status_93Ek7FSj3yChMeta } from "/app/pages/users/citizens/[status].vue?macro=true";
import { default as cityXfKA9D7qrbMeta } from "/app/pages/users/city.vue?macro=true";
import { default as _91personId_932mtBpsEM5oMeta } from "/app/pages/users/details/[personId].vue?macro=true";
export default [
  {
    name: "admin-bearerTokens",
    path: "/admin/bearerTokens",
    component: () => import("/app/pages/admin/bearerTokens.vue").then(m => m.default || m)
  },
  {
    name: "admin-disease-create",
    path: "/admin/disease/create",
    component: () => import("/app/pages/admin/disease/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-disease",
    path: "/admin/disease",
    component: () => import("/app/pages/admin/disease/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-disease-update-diseaseId",
    path: "/admin/disease/update/:diseaseId()",
    component: () => import("/app/pages/admin/disease/update/[diseaseId].vue").then(m => m.default || m)
  },
  {
    name: "admin-import-logs",
    path: "/admin/import-logs",
    component: () => import("/app/pages/admin/import-logs.vue").then(m => m.default || m)
  },
  {
    name: "admin-institution-types",
    path: "/admin/institution-types",
    component: () => import("/app/pages/admin/institution-types.vue").then(m => m.default || m)
  },
  {
    name: "admin-predisposition",
    path: "/admin/predisposition",
    component: () => import("/app/pages/admin/predisposition.vue").then(m => m.default || m)
  },
  {
    name: "admin-symptoms",
    path: "/admin/symptoms",
    component: () => import("/app/pages/admin/symptoms.vue").then(m => m.default || m)
  },
  {
    name: "admin-teams",
    path: "/admin/teams",
    component: () => import("/app/pages/admin/teams.vue").then(m => m.default || m)
  },
  {
    name: "admin-templates-documents",
    path: "/admin/templates/documents",
    component: () => import("/app/pages/admin/templates/documents/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-templates-messages",
    path: "/admin/templates/messages",
    component: () => import("/app/pages/admin/templates/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-templates-messages-update-templateId",
    path: "/admin/templates/messages/update/:templateId()",
    component: () => import("/app/pages/admin/templates/messages/update/[templateId].vue").then(m => m.default || m)
  },
  {
    name: "admin-vaccine",
    path: "/admin/vaccine",
    component: () => import("/app/pages/admin/vaccine.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    component: () => import("/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutCHyXH6UWwkMeta || {},
    component: () => import("/app/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerTCvAsPw3TEMeta || {},
    component: () => import("/app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset",
    path: "/auth/reset",
    meta: resetZNqLl9CTHjMeta || {},
    component: () => import("/app/pages/auth/reset.vue").then(m => m.default || m)
  },
  {
    name: "cases-create",
    path: "/cases/create",
    component: () => import("/app/pages/cases/create.vue").then(m => m.default || m)
  },
  {
    name: "cases-details-caseId",
    path: "/cases/details/:caseId()",
    component: () => import("/app/pages/cases/details/[caseId].vue").then(m => m.default || m)
  },
  {
    name: "cases-filter-highrisk",
    path: "/cases/filter/highrisk",
    component: () => import("/app/pages/cases/filter/highrisk.vue").then(m => m.default || m)
  },
  {
    name: "cases",
    path: "/cases",
    component: () => import("/app/pages/cases/index.vue").then(m => m.default || m)
  },
  {
    name: "cases-planned-notifications",
    path: "/cases/planned-notifications",
    component: () => import("/app/pages/cases/planned-notifications.vue").then(m => m.default || m)
  },
  {
    name: "cases-update-caseId",
    path: "/cases/update/:caseId()",
    component: () => import("/app/pages/cases/update/[caseId].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "institutions-details-institutionId",
    path: "/institutions/details/:institutionId()",
    component: () => import("/app/pages/institutions/details/[institutionId].vue").then(m => m.default || m)
  },
  {
    name: "institutions",
    path: "/institutions",
    component: () => import("/app/pages/institutions/index.vue").then(m => m.default || m)
  },
  {
    name: "outbreaks-details-outbreakId",
    path: "/outbreaks/details/:outbreakId()",
    component: () => import("/app/pages/outbreaks/details/[outbreakId].vue").then(m => m.default || m)
  },
  {
    name: "outbreaks",
    path: "/outbreaks",
    component: () => import("/app/pages/outbreaks/index.vue").then(m => m.default || m)
  },
  {
    name: "reports-casemap",
    path: "/reports/casemap",
    component: () => import("/app/pages/reports/casemap.vue").then(m => m.default || m)
  },
  {
    name: "reports-charts",
    path: "/reports/charts",
    component: () => import("/app/pages/reports/charts.vue").then(m => m.default || m)
  },
  {
    name: "reports-diseases",
    path: "/reports/diseases",
    component: () => import("/app/pages/reports/diseases.vue").then(m => m.default || m)
  },
  {
    name: "reports-institutions",
    path: "/reports/institutions",
    component: () => import("/app/pages/reports/institutions.vue").then(m => m.default || m)
  },
  {
    name: "self-service-cases-details-caseId",
    path: "/self-service/cases/details/:caseId()",
    component: () => import("/app/pages/self-service/cases/details/[caseId].vue").then(m => m.default || m)
  },
  {
    name: "self-service-cases",
    path: "/self-service/cases",
    component: () => import("/app/pages/self-service/cases/index.vue").then(m => m.default || m)
  },
  {
    name: "users-citizens-status",
    path: "/users/citizens/:status()",
    component: () => import("/app/pages/users/citizens/[status].vue").then(m => m.default || m)
  },
  {
    name: "users-city",
    path: "/users/city",
    component: () => import("/app/pages/users/city.vue").then(m => m.default || m)
  },
  {
    name: "users-details-personId",
    path: "/users/details/:personId()",
    component: () => import("/app/pages/users/details/[personId].vue").then(m => m.default || m)
  }
]