import { accountRoles } from '~/authorization'
import type { AccountRole, Role } from '~/types'

/*
 * Router Permissions
 * Which routes can roles access within the application?
 * Adding "no-role" will allow every role to use the rout
 *
 * WildCard Paths: Must end with a `/*` to allow any of the sub pages to be accessed
 */
const PERMISSIONS_BY_PATH = {
  // Public
  '/': ['no-role'],
  '/auth/logout': accountRoles.options,
  '/imprint': ['no-role'],
  '/privacy': ['no-role'],
  '/accessibility': ['no-role'],
  '/self-service/*': ['person'],

  // Accounts
  '/users/city': ['admin', 'manager'],
  '/users/citizens': ['admin', 'manager', 'specialist', 'clerk'],
  '/users/citizens/*': ['admin', 'manager', 'specialist', 'clerk'],
  '/users/details/*': ['admin', 'manager', 'specialist', 'clerk'],

  // Institutions
  '/institutions': ['admin', 'manager', 'specialist', 'clerk'],
  '/institutions/details/*': ['admin', 'manager', 'specialist', 'clerk'],

  // Administration
  '/admin': ['admin', 'manager', 'specialist'],
  '/admin/disease': ['admin', 'manager', 'specialist'],
  '/admin/disease/create': ['admin', 'manager'],
  '/admin/disease/update/*': ['admin', 'manager'],
  '/admin/vaccine': ['admin', 'manager', 'specialist'],
  '/admin/predisposition': ['admin', 'manager', 'specialist'],
  '/admin/symptoms': ['admin', 'manager', 'specialist'],
  '/admin/teams': ['admin', 'manager', 'specialist'],
  '/admin/institution-types': ['admin', 'manager', 'specialist'],
  '/admin/bearerTokens': ['admin', 'manager'],
  '/admin/import-logs': ['admin', 'manager'],

  '/admin/templates/messages': ['admin', 'manager', 'specialist'],
  '/admin/templates/messages/update/*': ['admin', 'manager'],
  '/admin/templates/documents': ['admin', 'manager', 'specialist'],

  // Reports
  '/reports': ['admin', 'manager', 'specialist'],
  '/reports/*': ['admin', 'manager', 'specialist'],

  // Cases
  '/cases': ['admin', 'manager', 'specialist', 'clerk'],
  '/cases/filter/highrisk': ['admin', 'manager', 'specialist', 'clerk'],
  '/cases/create': ['admin', 'manager', 'specialist', 'clerk'],
  '/cases/update/*': ['admin', 'manager', 'specialist', 'clerk'],
  '/cases/details/*': ['admin', 'manager', 'specialist', 'clerk'],
  '/cases/planned-notifications': ['admin', 'manager', 'specialist', 'clerk'],

  // Outbreaks
  '/outbreaks': ['admin', 'manager', 'specialist', 'clerk'],
  '/outbreaks/details/*': ['admin', 'manager', 'specialist', 'clerk'],
} as const satisfies Record<string, Role[]>
export type PathPermissions = keyof typeof PERMISSIONS_BY_PATH

/*
 * Page Permissions
 * Extra permissions that can be reused in the back and frontend to specify certain features.
 */

const PAGE_PERMISSIONS = {
  // Accounts
  'account.role.update': ['admin', 'manager'],
  'account.status.update': ['admin', 'manager', 'specialist'],
  'account.tab.cases': ['admin', 'manager', 'specialist', 'clerk', 'person'],
  'account.tab.immunizations': ['admin', 'manager', 'specialist', 'clerk'],
  'immunization.create.others': ['admin', 'manager', 'specialist', 'clerk'],
  'account.tab.documents': ['admin', 'manager', 'specialist', 'clerk', 'person'],
  'account.tab.events': ['admin', 'manager', 'specialist', 'clerk'],
  'account.tab.institutions': ['admin', 'manager', 'specialist', 'clerk'],
  'account.delete': ['admin', 'manager'],
  'account.merge': ['admin', 'manager'],
  'account.create.subperson': ['admin', 'manager', 'specialist', 'clerk'],
  'account.create.empty': ['admin', 'manager', 'specialist', 'clerk'],
  'person.export': ['admin', 'manager', 'specialist', 'clerk'],

  // Notes
  'notes.view': ['admin', 'manager', 'specialist', 'clerk'],
  'notes.create': ['admin', 'manager', 'specialist', 'clerk'],
  'notes.update.others': ['admin', 'manager'],
  'notes.delete.others': ['admin', 'manager'],

  // Administration
  'documents.template.create': ['admin', 'manager'],
  'documents.template.update': ['admin', 'manager'],
  'teams.create': ['admin', 'manager'],
  'teams.update': ['admin', 'manager'],
  'messages.template.create': ['admin', 'manager'],
  'messages.send': ['admin', 'manager', 'specialist', 'clerk'],

  // Institutions
  'institutions.members.update': ['admin', 'manager', 'specialist', 'clerk'],
  'institutions.members.delete': ['admin', 'manager', 'specialist', 'clerk', 'institution'],
  'institutions.members.seeAll': ['admin', 'manager', 'specialist', 'clerk'],
  'institutions.tab.cases': ['admin', 'manager', 'clerk', 'specialist', 'institution'],
  'institutions.tab.documents': ['admin', 'manager', 'clerk', 'specialist', 'institution'],
  'institutions.csv.import': ['admin', 'manager'],

  // Cases
  'case.team.update': ['admin', 'manager', 'specialist', 'clerk'],
  'case.tab.contactCases': ['admin', 'manager', 'specialist', 'clerk'],
  'case.tab.notification': ['admin', 'manager', 'specialist', 'clerk'],
  'case.tab.diseaseTests': ['admin', 'manager', 'specialist', 'clerk'],
  'case.csv.import': ['admin', 'manager', 'specialist'],
  'case.export': ['admin', 'manager', 'specialist', 'clerk'],
  'case.status.update': ['admin', 'manager', 'specialist', 'clerk'],
  'case.closed.update': ['admin', 'manager', 'specialist', 'clerk'],

  'symptomDiaryEntries.viewCreator': ['admin', 'manager', 'specialist', 'clerk'],
  'diseaseVariant.use': ['admin', 'manager', 'specialist', 'clerk'],
  'hospitalizations.tab': ['admin', 'manager', 'specialist', 'clerk'],

  // Outbreaks
  'outbreaks.state.update': ['admin', 'manager', 'specialist', 'clerk'],

  // Documents
  'documents.create.others': ['admin', 'manager', 'specialist', 'clerk'],
  'documents.sendPostal': ['admin', 'manager', 'specialist', 'clerk'],
  'documents.viewHidden': ['admin', 'manager', 'specialist', 'clerk'],
  'documents.delete': ['admin', 'manager'],
  'documents.generate': ['admin', 'manager', 'specialist', 'clerk'],

  // Filter Templates
  'filter.template.create': ['admin', 'manager'],
  'filter.template.delete': ['admin', 'manager'],
  'filter.use': ['admin', 'manager', 'specialist', 'clerk'],

  // DEMIS
  'demis.sendReport': ['admin', 'manager', 'specialist'],
} as const satisfies Record<string, Role[]>
export type PagePermissions = keyof typeof PAGE_PERMISSIONS

/*
 * Role updating Permissions
 * Which roles can a role update an account to?
 */

export const allowedRolesToSet: Partial<Record<Role, Role[]>> = {
  admin: accountRoles.options,
  manager: ['manager', 'specialist', 'clerk', 'person'],
  specialist: ['person'],
  clerk: ['person'],
  institution: ['institution'],
}

/*
 * Authorization functions
 * Determine is a role can access a certain page or feature
 */

export function roleToSet(payload: { role: AccountRole, previousRole?: AccountRole }, ownRole: Role): AccountRole {
  if (payload.previousRole && payload.previousRole === 'institution') {
    return 'institution'
  }
  // If the role has not changed, allow the update
  if (payload.previousRole && payload.role === payload.previousRole) {
    return payload.role
  }

  // Check if the account can set this permission, otherwise fallback to the previous role or person
  const fallbackRole = payload.previousRole ?? 'person'
  return allowedRolesToSet[ownRole]?.includes(payload.role) ? payload.role : fallbackRole
}

function can(key: string, role: Role, allowedRoles: Role[]) {
  if (!allowedRoles) {
    console.warn(`can: No rules found for this key, denying access. Key is: ${key}`)
    return false
  }

  // no-role means that even people without an account can use this
  if (allowedRoles.includes('no-role')) {
    return true
  }

  // Admins can use everything
  if (role === 'admin') {
    return true
  }

  return allowedRoles.includes(role)
}

export const canUse = (role: Role = 'no-role', key: PagePermissions) => can(key, role, PAGE_PERMISSIONS[key] ?? [])
export const canAccess = (role: Role = 'no-role', key: PathPermissions | string) => {
  const staticPermission = PERMISSIONS_BY_PATH[key as PathPermissions]
  if (staticPermission) {
    return can(key, role, staticPermission)
  }

  const wildCardRouteKeys = Object.keys(PERMISSIONS_BY_PATH).filter(path => path.endsWith('/*'))
  const wildCardPermission = wildCardRouteKeys.filter(path => key.startsWith(path.replace('*', ''))).flatMap(path => PERMISSIONS_BY_PATH[path as PathPermissions])

  return can(key, role, wildCardPermission)
}
