import type { NotificationOptions } from 'naive-ui'

const duration = 5 * 1000
const naiveNotification = useNaiveNotification()

export default (type: NotificationOptions['type'], title: string, content?: NotificationOptions['content']) => {
  naiveNotification.create({
    type,
    title,
    content,
    duration,
    keepAliveOnHover: true,
  })
}
