export default () => {
  const componentKey = ref(0)

  const forceRerender = () => {
    componentKey.value += 1
  }

  return {
    componentKey,
    forceRerender,
  }
}
