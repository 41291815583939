<script setup lang="ts">
const { locale } = useI18n()
const { componentKey, forceRerender } = useForceRerender()
watch(locale, () => {
  forceRerender()
})

const { openAccountMergePopup } = useOpeners()
</script>

<template>
  <StyleConfig>
    <NuxtLayout>
      <NuxtPage :key="componentKey" />

      <!-- Global Modals -->
      <LazyAccountMergePopup :opener="openAccountMergePopup" />
    </NuxtLayout>
  </StyleConfig>
</template>
