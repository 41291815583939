import { defineFormKitConfig } from '@formkit/vue'
import { de, en } from '@formkit/i18n'
import { applicationIcons, inputIcons } from '@formkit/icons'
import type { FormKitNode } from '@formkit/core'
import { rootClasses } from './formkit.theme'
import { address, dateOnOrAfter, email, mobilePhone } from './components/FormKit/rules'

const legends: string[] = ['checkbox_multi', 'radio_multi', 'repeater', 'transferlist']

function addAsteriskPlugin(node: FormKitNode): void {
  if (['button', 'submit', 'hidden', 'group', 'list', 'meta'].includes(node.props.type)) {
    return
  }

  node.on('created', () => {
    const legendOrLabel = legends.includes(`${node.props.type}${node.props.options ? '_multi' : ''}`) ? 'legend' : 'label'
    if (node.props.definition?.schemaMemoKey) {
      node.props.definition.schemaMemoKey += `${node.props.options ? '_multi' : ''}_add_asterisk`
    }
    const schemaFn = node.props.definition?.schema
    if (node.props.definition && typeof schemaFn === 'function') {
      node.props.definition.schema = (sectionsSchema: Record<string, any> = {}): any => {
        sectionsSchema[legendOrLabel] = {
          children: ['$label', {
            $el: 'span',
            if: '$state.required',
            attrs: {
              class: '$classes.asterisk',
            },
            children: ['*'],
          }],
        }

        return schemaFn(sectionsSchema)
      }
    }
  })
}

export default defineFormKitConfig({
  plugins: [addAsteriskPlugin],
  locales: { de, en },
  locale: 'de',
  icons: {
    ...inputIcons,
    ...applicationIcons,
  },
  config: {
    rootClasses,
  },
  rules: {
    email,
    mobilePhone,
    dateOnOrAfter,
    address,
  },
})
