import type { DefaultSession } from '~/next-auth'
import type { RouterOutput } from '~/types'

export default (account?: RouterOutput['accounts']['findOneAccount'], session?: DefaultSession | null) => {
  if (account?.person) {
    return `${account.person.firstName} ${account.person.lastName}`
  }
  if (account?.institution) {
    return account.institution.name
  }

  if (!session) {
    return 'N/A'
  }

  if (session.user.role === 'institution') {
    return session.user.name
  }

  return `${session.user.firstName} ${session.user.lastName}`
}
