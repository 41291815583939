import { cityRoles } from '~/authorization'
import type { PagePermissions, PathPermissions } from '~/authorization/permissions'
import { canAccess, canUse } from '~/authorization/permissions'
import type { Role } from '~/types'

export default () => {
  const { data: session } = useAuth()
  const { accountQuery } = useQuery()
  const { data: account } = accountQuery.findOne({ id: session.value?.user.id })

  const isAuthenticated = computed(() => !!session.value?.user)
  const isInstitution = computed(() => Boolean(!account.value?.person && account.value?.institution))

  const personIds = computed(() => {
    if (!session.value || session.value.user.role === 'institution') {
      return []
    }
    return [
      session.value.user.personId,
      ...session.value.user.legalRepresentativeOf,
    ]
  })

  const accountPersons = computed(() => {
    if (!account.value) {
      return []
    }

    const subAccounts = account.value.legalRepresentativeOf.map(({ id, firstName, lastName }) => ({
      name: `${firstName} ${lastName}`,
      id,
    }))
    return [{ id: account.value.personId, name: formattedFullname.value }, ...subAccounts]
  })

  const email = computed(() => {
    if (account.value) {
      return account.value.email
    }
    return session.value?.user.email ?? 'N/A'
  })

  const formattedFullname = computed(() => usePersonName(account.value, session.value))

  const role = computed(() => {
    if (account.value?.role) {
      return account.value.role as Role
    }
    return (session.value?.user.role ?? 'no-role') as Role
  })

  const isRole = (roles: Role[] | Role = 'admin') => {
    const currentUserRole = role.value

    // Admin has access to every page
    if (currentUserRole === 'admin') {
      return true
    }

    // If no role is specified: no access
    if (!roles) {
      return false
    }

    if (typeof roles === 'string') {
      return currentUserRole === roles
    }

    return roles.includes(currentUserRole)
  }
  const isCityAccount = computed(() => isRole(cityRoles))

  return {
    isAuthenticated,
    isInstitution,
    personIds,
    accountPersons,
    session,
    account,
    formattedFullname,
    email,
    role,
    isRole,
    isCityAccount,
    canAccessPage: (path: PathPermissions) => canAccess(role.value, path),
    canUse: (path: PagePermissions) => canUse(role.value, path),
  }
}
