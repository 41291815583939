<script lang="ts" setup>
const { locale } = useI18n()
const selectedCookieConfirmLang = computed(() => locale.value === 'de' ? 'de' : 'en')
</script>

<template>
  <CookieControl :locale="selectedCookieConfirmLang" />
</template>

<style>
.cookieControl__Bar {
  @apply !border;
}

.cookieControl__BarContainer {
  @apply !p-8;
}

.cookieControl__Bar button {
  @apply !rounded !py-2 !px-3 !text-sm;
}

.cookieControl__ModalContent {
  @apply !rounded;
}

.cookieControl__ModalButtons {
  @apply !gap-2;
}

.cookieControl__ModalButtons button {
  @apply !rounded !py-2 !px-4 !text-sm;
}

.cookieControl__ModalContent {
  @apply !overflow-y-auto;
}

.cookieControl__ModalClose {
  @apply !rounded !py-2 !px-4 !text-sm;
}
</style>
