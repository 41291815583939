import type { DehydratedState, VueQueryPluginOptions } from '@tanstack/vue-query'
import { QueryCache, QueryClient, VueQueryPlugin, dehydrate, hydrate } from '@tanstack/vue-query'
import { useState } from '#app'

export default defineNuxtPlugin((nuxt) => {
  const { $i18n } = useNuxtApp()
  const vueQueryState = useState<DehydratedState | null>('vue-query')

  // Modify your Vue Query global settings here
  const queryClient = new QueryClient({
    defaultOptions: { queries: { staleTime: 15000, retry: 1 } },
    queryCache: new QueryCache({
      onError: (error) => {
        console.error(error)
        notify('error', $i18n.t('notifications.error.default'))
      },
    }),
  })

  const options: VueQueryPluginOptions = { queryClient }

  nuxt.vueApp.use(VueQueryPlugin, options)

  if (process.server) {
    nuxt.hooks.hook('app:rendered', () => {
      vueQueryState.value = dehydrate(queryClient)
    })
  }

  if (process.client) {
    nuxt.hooks.hook('app:created', () => {
      hydrate(queryClient, vueQueryState.value)
    })
  }
})
