import { canAccess } from '~/authorization/permissions'

export default defineNuxtRouteMiddleware((to) => {
  if (to.path.startsWith('/auth')) {
    return
  }

  const { role } = useAuthorization()
  if (!canAccess(role.value, to.path)) {
    return navigateTo('/')
  }
})
