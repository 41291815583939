import { z } from 'zod'
import type { AccountRole } from '~/types'

/**
 * - Hidden roles: Placeholder roles that cannot be assigned to accounts
 * - Account Roles: Roles that can be assigned to accounts
 *
 * When adding a role, please
 *  - Add it to roles
 *  - Add the translation key to ~/locales/de.json and any other languages (role.ROLE_KEY)
 *  - Assign the roles to the correct groups at the bottom, to set their permissions in the backend
 *  - Adjust their frontend permissions in ~/authorization/permissions.ts
 *
 * Updated by:
 * - 31.07.24 https://github.com/sidestream-tech/dikoma/issues/566
 */

const hiddenRoles = z.enum([
  // Not logged in
  'no-role',
])

export const accountRoles = z.enum([
  // Authenticated with supreme priviledges
  'admin',

  // Authenticated with some priviledges
  'manager',
  'specialist',
  'clerk',

  // Authenticated but without being a city employee
  'person',
  'institution',
])

export const roles = z.enum([...hiddenRoles.options, ...accountRoles.options])
export const cityRoles: AccountRole[] = ['admin', 'manager', 'specialist', 'clerk']
