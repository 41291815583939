<script setup lang="ts">
import { dateDeDE, dateEnGB, deDE, enGB } from 'naive-ui'
import type { ThemeConfig } from '@bg-dev/nuxt-naiveui'
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'
import theme from '#tailwind-config/theme'

// Themeing
const themeConfig: ThemeConfig = {
  shared: {
    common: {
      primaryColor: theme.colors.primary['500'],
      primaryColorHover: theme.colors.primary['300'],
      borderRadius: theme.borderRadius.DEFAULT,
      successColor: theme.colors.success.DEFAULT,
      errorColor: theme.colors.error.DEFAULT,
    },
    Button: {
      color: '#f7f4f2',
      textColor: theme.colors.black,
      border: `1px solid ${theme.colors.black}`,
      borderRadiusSmall: '1.4em',
      borderRadiusMedium: '1.4em',
      borderRadiusLarge: '1.4em',
      borderFocus: `2px solid ${theme.colors.black}`,
      borderFocusPrimary: `2px solid ${theme.colors.black}`,
      paddingMedium: '15px 18px',
      paddingTiny: '10px 15px',
      fontSizeTiny: '12px',
    },
    Notification: {
      boxShadow: theme.boxShadow.DEFAULT,
      color: theme.colors.gray[200],
      descriptionTextColor: theme.colors.gray['600'],
    },
    Tooltip: {
      color: 'white',
      textColor: 'black',
    },
    Menu: {
      // Item container
      borderRadius: '1em',
      itemColorHover: theme.colors.beige,
      itemColorActive: theme.colors.beige,
      // Hover
      itemTextColorHover: theme.colors.black,
      itemTextColorActiveHover: theme.colors.black,
      itemIconColorActiveHover: theme.colors.black,
      itemColorActiveHover: theme.colors.beige,
      // Active
      itemTextColorActive: theme.colors.black,
      itemIconColorActive: theme.colors.black,
      // Parent
      itemTextColorChildActive: theme.colors.black,
      itemIconColorChildActive: theme.colors.black,
      itemTextColorChildActiveHover: theme.colors.black,
      itemIconColorChildActiveHover: theme.colors.black,
      // Arrow
      arrowColorActive: theme.colors.black,
      arrowColorChildActive: theme.colors.black,
      arrowColorActiveHover: theme.colors.black,
      arrowColorChildActiveHover: theme.colors.black,
    },
    Dropdown: {
      optionColorActive: theme.colors.beige,
    },
    DataTable: {
      thColor: theme.colors.beige,
      thColorHover: theme.colors.primary['25'],
      thTextColor: theme.colors.black,
      thFontWeight: theme.fontWeight.semibold,
      thIconColor: theme.colors.black,
      tdColorHover: theme.colors.gray['100'],
      thPaddingLarge: '18px',
      tdPaddingLarge: '18px',
      loadingSize: '40px',
      opacityLoading: '50%',
    },
    Tag: {
      borderRadius: theme.borderRadius.DEFAULT,
    },
    Alert: {
      padding: '20px',
    },
    Input: {
      placeholderColor: theme.colors.gray[600],
    },
    Pagination: {
      itemTextColorDisabled: theme.colors.gray[600],
    },
  },
}

// Internationalization
const { locale } = useI18n()
const naiveLangConfig = computed(() => {
  if (locale.value === 'de') {
    return { locale: deDE, dateLocale: dateDeDE }
  }
  return { locale: enGB, dateLocale: dateEnGB }
})

const i18nHead = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})
useHead({
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
  meta: [...(i18nHead.value.meta || [])],
})
</script>

<template>
  <naive-config :locale="naiveLangConfig.locale" :date-locale="naiveLangConfig.dateLocale" :theme-config="themeConfig">
    <NNotificationProvider placement="top" :max="3">
      <naive-notification />
      <slot />
    </NNotificationProvider>
    <VueQueryDevtools />
    <TheCookieConsent />
  </naive-config>
</template>

<style>
body { @apply text-base font-kiso }

/* Text styles */
h1 { @apply text-xl xl:text-2xl leading-5 font-bold tracking-wide }
h2 { @apply text-xl font-semibold tracking-wide }
h3 { @apply text-lg font-semibold }
h4 { @apply text-base font-semibold }

/* Notification style override to properly center icon and text */
.n-notification-container .n-notification { @apply flex items-start gap-2 py-3 }
.n-notification-container .n-notification .n-notification__avatar { @apply relative top-0 left-0 }
.n-notification-container .n-notification.n-notification--show-avatar .n-notification-main { @apply ml-0 py-0 }
.n-notification-main__header { @apply !font-semibold }
.n-notification-container .n-notification .n-notification-main .n-notification-main__content { @apply mt-1 }

/* Icon Styles */
.nuxt-icon {
  width: 100% !important;
  height: 100% !important;
  margin-bottom: 0 !important;
}

.icon {
  overflow: hidden;
}
</style>
